import { BaseHTMLAttributes, FC, memo } from "react";
import { Autoplay, EffectCreative, Keyboard, Lazy, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import { RequestBanner } from "../../../../contracts/contracts";
import { cssBullet, cssBulletActive } from "../../Swiper/StyledSwiper";
import { SwiperWrapper } from "../../Swiper/SwiperWrapper";
import { BannerSlide } from "./Slide";
import { StyledSlider, StyledSliderInner } from "./Styled";
export const SliderBanners: FC<{
  banners?: RequestBanner[];
} & BaseHTMLAttributes<HTMLDivElement>> = memo(({
  banners,
  ...props
}) => {
  const items = [...(banners || [])].reverse().sort((a, b) => {
    const valA = a.weight || 0;
    const valB = b.weight || 0;
    return valA < valB ? -1 : valA > valB ? 1 : 0;
  });
  if (!items.length) {
    return null;
  }
  return <StyledSlider {...props}>
      <StyledSliderInner>
        <SwiperWrapper loop={true} modules={[EffectCreative, Autoplay, Pagination, Keyboard, Lazy]} lazy={{
        enabled: true
      }} pagination={{
        bulletClass: cssBullet,
        bulletActiveClass: cssBulletActive,
        clickable: true
      }} speed={900} keyboard={true} autoplay={{
        delay: 3000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      }} effect={"creative"} creativeEffect={{
        prev: {
          translate: ["-20%", 0, -1]
        },
        next: {
          translate: ["100%", 0, 0]
        }
      }}>
          {items.map((b, index) => <SwiperSlide key={index}>
              <BannerSlide banner={b} priority={index === 0} />
            </SwiperSlide>)}
        </SwiperWrapper>
      </StyledSliderInner>
    </StyledSlider>;
});
SliderBanners.displayName = "SliderBanners";