import { QueryClient } from "react-query"
import type {
  BannerApiType,
  ProductCatalogType,
} from "../../contracts/contracts"
import { fetchBanners } from "../api/bannersAPI"
import { fetchCatalog, fetchHits } from "../api/catalogAPI"
import { Homepage, HomepagePropsType } from "../components/Homepage"
import { Meta } from "../components/Meta/Meta"
import type { CatalogResponseType } from "../types/types"

export const getServerSideProps = async (): Promise<{
  props: HomepagePropsType
}> => {
  const queryClient = new QueryClient()

  const banners: BannerApiType[] | null = await queryClient.fetchQuery(
    ["banners"],
    () =>
      fetchBanners({
        server: true,
      }),
    {
      staleTime: Infinity,
    },
  )

  const hits: ProductCatalogType[] | null = await queryClient.fetchQuery(
    ["hits"],
    () =>
      fetchHits({
        server: true,
      }),
    {
      staleTime: Infinity,
    },
  )

  const newProducts: CatalogResponseType | null = await queryClient.fetchQuery(
    ["new"],
    () =>
      fetchCatalog({
        isEnabled: "1",
        page: 1,
        perPage: 20,
        isNew: true,
        server: true,
      }),
    {
      staleTime: Infinity,
    },
  )

  return {
    props: {
      banners: banners || [],
      hits: hits || [],
      new: newProducts || null,
    },
  }
}

export default function Home({
  banners: bannersSSR,
  hits: hitsSSR,
  new: newSSR,
}: HomepagePropsType): JSX.Element {
  return (
    <>
      <Meta />
      <Homepage banners={bannersSSR} hits={hitsSSR} new={newSSR} />
    </>
  )
}
