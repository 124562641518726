import { FC, useEffect, useMemo } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import type { BannerApiType, ProductCatalogType } from "../../../contracts/contracts";
import { useApp } from "../../hooks/app";
import { cssHiddenMD, cssSectionUnderHeader } from "../../styles/utils/Utils";
import type { CatalogResponseType } from "../../types/types";
import { makeBanners } from "../../utils/helpers";
import { SingleHome } from "../Banners/SingleHome";
import { SliderBanners } from "../Banners/Slider";
import type { BusinessAreasBannersPropsType } from "../BusinessAreasBanners";
import { MainPageRecommends } from "../LeadHit";
import type { HitsPropsType } from "./Hits";
import type { NewPropsType } from "./New";
const PersonalRecommendations = dynamic(((() => import("./PersonalRecommendations").then(mod => mod.PersonalRecommendations)) as DynamicOptions), {
  ssr: false
});
const BusinessAreasBanners = dynamic(((() => import("../../components/BusinessAreasBanners").then(mod => mod.BusinessAreasBanners)) as DynamicOptions<BusinessAreasBannersPropsType>));
const BrandBasis = dynamic(((() => import("../../components/BrandBasis").then(mod => mod.BrandBasis)) as DynamicOptions));
const Hits = dynamic(((() => import("./Hits").then(mod => mod.Hits)) as DynamicOptions<HitsPropsType>));
const New = dynamic(((() => import("./New").then(mod => mod.New)) as DynamicOptions<NewPropsType>));
export type SSRPropsType = {
  banners?: BannerApiType[];
  hits?: ProductCatalogType[];
  new?: CatalogResponseType | null;
};
export type HomepagePropsType = SSRPropsType;
export const Homepage: FC<HomepagePropsType> = ({
  banners: bannersSSR,
  hits: hitsSSR,
  new: newSSR
}) => {
  const {
    banners: bannersHook,
    updateBanners
  } = useApp();
  const banners = useMemo(() => !bannersHook && !!bannersSSR ? makeBanners(bannersSSR) : bannersHook, [bannersHook, bannersSSR]);
  const bannersSlider = banners !== null ? banners.main_slider : undefined;
  const bannerSingle = banners !== null ? banners.main_single[0] : undefined;
  useEffect(() => {
    if (bannersHook === null) {
      updateBanners(bannersSSR);
    }
  }, [bannersSSR, updateBanners, bannersHook]);
  return <>
      <SliderBanners banners={bannersSlider} className={cssSectionUnderHeader} />
      <Hits initial={hitsSSR} />
      <New initial={newSSR || undefined} />
      <BusinessAreasBanners className={cssHiddenMD} />
      <PersonalRecommendations />
      <SingleHome banner={bannerSingle} />
      <MainPageRecommends />
      <BrandBasis />
    </>;
};