import { FC } from "react";
import { ImageProps } from "next/image";
import { RequestBanner } from "../../../../../contracts/contracts";
import { Banner } from "../../Banner";
import { cssBannerSlide } from "./Styled";
export const BannerSlide: FC<{
  banner: RequestBanner;
} & Pick<ImageProps, "priority" | "loading">> = ({
  banner,
  priority = false,
  loading
}) => {
  if (!banner.desktop) {
    return null;
  }
  return <Banner withHover={false} className={cssBannerSlide} url={banner.url} image={{
    desktop: {
      src: banner.desktop,
      width: 1680,
      height: 450,
      objectFit: "cover",
      layout: "responsive"
    },
    tablet: {
      src: banner.tablet,
      width: 768,
      height: 320,
      objectFit: "cover",
      layout: "responsive"
    },
    mobile: {
      src: banner.mobile,
      width: 375,
      height: 270,
      objectFit: "cover",
      layout: "responsive"
    }
  }} priority={priority} quality={100} unoptimized loading={!!loading ? loading : priority ? undefined : "lazy"} />;
};