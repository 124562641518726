import { FC } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { cx } from "@linaria/core";
import type { Swiper as SwiperType } from "swiper";
import type { SwiperProps } from "swiper/react";
import { Swiper } from "swiper/react";
import type { NavigationOptions } from "swiper/types/modules/navigation";
import type { SwiperPaginationSequencePropsType } from "./PaginationSequence";
import { cssCustomSwiper } from "./StyledSwiper";
import "swiper/css";
import "swiper/css/pagination";
const SwiperPaginationSequence = dynamic(((() => import("./PaginationSequence").then(mod => mod.SwiperPaginationSequence)) as DynamicOptions<SwiperPaginationSequencePropsType>), {
  ssr: false
});
export type NavigationInitPropsType = {
  swiper: SwiperType;
} & Pick<NavigationOptions, "prevEl" | "nextEl">;
type NavigationInitType = (props: NavigationInitPropsType) => void;
export type SwiperWrapperPropsType = SwiperProps & {
  paginationSequence?: boolean;
  path?: string;
} & SwiperPaginationSequencePropsType;
export const navigationInit: NavigationInitType = ({
  swiper,
  nextEl,
  prevEl
}) => {
  if (swiper.params.navigation !== undefined) {
    swiper.params.navigation["prevEl"] = prevEl;
    swiper.params.navigation["nextEl"] = nextEl;
  }
  swiper.navigation.init();
  swiper.navigation.update();
};
export const navigationDestroy = ({
  swiper
}: {
  swiper: SwiperType;
}): void => {
  swiper.navigation.destroy();
};
export const SwiperWrapper: FC<SwiperWrapperPropsType> = ({
  paginationSequence = false,
  path,
  count,
  className,
  children,
  ...props
}) => {
  return <Swiper {...props} className={cx(className, cssCustomSwiper)}>
      {children}
      {paginationSequence && <SwiperPaginationSequence count={count} path={path} />}
    </Swiper>;
};