import dynamic, { DynamicOptions } from "next/dynamic"
import { ModalConfigType } from "./types"

const NotFindNeedForm = dynamic(
  (() =>
    import("../Forms/NotFindNeed").then(
      (mod) => mod.NotFindNeedForm,
    )) as DynamicOptions,
  {
    ssr: false,
  },
)

const MODAL_CONFIG: ModalConfigType = {
  notfindneed: {
    title: "Не нашли нужного?",
    variant: "rounded-0",
    Component: () => NotFindNeedForm,
  },
}
const enum ViewModeBanner {
  DESKTOP = "desktop",
  TABLET = "tablet",
  MOBILE = "mobile",
}

export { ViewModeBanner, MODAL_CONFIG }
